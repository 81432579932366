import { useTranslation } from 'react-i18next'

import { Headline } from 'src/components/Headline/Headline'

import styles from './Footer.module.scss'

export const ShippingPartners = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.section}>
      <Headline variant="h4" className={styles.headline}>
        {t('footer.shipmentOptionsHeadline')}
      </Headline>
      <div>
        <img
          src="/assets/img/footer/shipping/dhl-logo.webp"
          alt="DHL Logo"
          title="DHL Logo"
          width="143"
          height="80"
          loading="lazy"
        />
      </div>
    </div>
  )
}
