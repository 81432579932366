interface CoffeeCircleLogoProps {
  fill?: string
}

export const CoffeeCircleLogo = ({ fill }: CoffeeCircleLogoProps) => (
  <svg width="161" height="47" viewBox="0 0 161 47" fill={fill || 'none'} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M98.547 8.44826C98.547 4.94752 100.842 3.93071 104.164 3.93071V0C98.5761 0.081345 94.7148 2.4694 94.7148 8.33496V19.8511H98.547V11.734H104.164V8.45117L98.547 8.44826Z"
        fill={fill || 'currentColor'}
      />
      <path
        d="M84.2725 33.4997C84.2725 29.9989 86.5671 28.9821 89.8897 28.9821V25.0514C84.3016 25.1327 80.4403 27.5208 80.4403 33.3864V44.9025H84.2725V33.4997Z"
        fill={fill || 'currentColor'}
      />
      <path
        d="M111.243 8.44826C111.243 4.94752 113.538 3.93071 116.86 3.93071V0C111.272 0.081345 107.411 2.4694 107.411 8.33496V19.8511H111.243V11.734H116.86V8.45117L111.243 8.44826Z"
        fill={fill || 'currentColor'}
      />
      <path
        d="M116.307 37.0178C116.307 40.5186 118.602 41.5354 121.924 41.5354V45.4661C116.336 45.3847 112.475 42.9967 112.475 37.1311V25.615H116.307V37.0178Z"
        fill={fill || 'currentColor'}
      />
      <path d="M76.0928 25.6179H72.2402V44.8995H76.0928V25.6179Z" fill={fill || 'currentColor'} />
      <path
        d="M81.0314 16.7977C77.3012 16.7977 74.596 14.0378 74.596 10.2378C74.596 6.44657 77.3041 3.69827 81.0314 3.69827C84.7005 3.69827 87.4669 6.51049 87.4669 10.2378C87.4698 14.0407 84.7617 16.7977 81.0314 16.7977ZM81.0314 0.0406494C75.1347 0.0406494 70.6881 4.42457 70.6881 10.2378C70.6881 16.0627 75.1347 20.4553 81.0314 20.4553C86.9398 20.4553 91.3922 16.0627 91.3922 10.2378C91.3951 4.42457 86.9398 0.0406494 81.0314 0.0406494Z"
        fill={fill || 'currentColor'}
      />
      <path
        d="M132.614 28.5986C135.421 28.5986 137.489 30.5015 137.992 33.488H126.933C127.544 30.4579 129.682 28.5986 132.614 28.5986ZM132.672 25.0804C127.003 25.0804 122.888 29.3772 122.888 35.298C122.888 41.303 126.866 45.4951 133.039 45.4951C138.421 45.4951 141.181 42.1629 141.181 42.1629L139.049 39.4524C139.049 39.4524 136.74 42.0176 133.115 42.0176C129.708 42.0176 127.402 40.0218 126.875 36.7709H141.851C141.851 36.7709 141.959 35.9807 141.959 35.2195V35.2166C141.918 29.2494 138.1 25.0804 132.672 25.0804Z"
        fill={fill || 'currentColor'}
      />
      <path
        d="M101.936 41.8404C98.346 41.8404 95.7369 39.0805 95.7369 35.2805C95.7369 31.4892 98.3431 28.7409 101.936 28.7409C105.116 28.7409 107.099 31.1087 107.099 31.1087L109.592 28.4911C109.592 28.4911 107.198 25.0833 102.015 25.0833C96.1154 25.0833 91.8319 29.3714 91.8319 35.2805C91.8319 41.2013 96.1154 45.498 102.015 45.498C107.245 45.498 109.586 42.099 109.586 42.099L107.163 39.4465C107.166 39.4465 105.166 41.8404 101.936 41.8404Z"
        fill={fill || 'currentColor'}
      />
      <path
        d="M61.0058 16.8007C57.4153 16.8007 54.8062 14.0407 54.8062 10.2408C54.8062 6.4495 57.4124 3.7012 61.0058 3.7012C64.1857 3.7012 66.1687 6.06892 66.1687 6.06892L68.6614 3.45136C68.6614 3.45136 66.2677 0.0435791 61.0844 0.0435791C55.1848 0.0435791 50.9012 4.33162 50.9012 10.2408C50.9012 16.1615 55.1848 20.4583 61.0844 20.4583C66.3143 20.4583 68.6555 17.0592 68.6555 17.0592L66.2328 14.4068C66.2328 14.4068 64.2323 16.8007 61.0058 16.8007Z"
        fill={fill || 'currentColor'}
      />
      <path
        d="M61.0058 41.823C57.4153 41.823 54.8062 39.0631 54.8062 35.2631C54.8062 31.4718 57.4124 28.7235 61.0058 28.7235C64.1857 28.7235 66.1687 31.0913 66.1687 31.0913L68.6614 28.4737C68.6614 28.4737 66.2677 25.0659 61.0844 25.0659C55.1848 25.0659 50.9012 29.354 50.9012 35.2631C50.9012 41.1839 55.1848 45.4806 61.0844 45.4806C66.3143 45.4806 68.6555 42.0816 68.6555 42.0816L66.2328 39.4291C66.2328 39.4291 64.2323 41.823 61.0058 41.823Z"
        fill={fill || 'currentColor'}
      />
      <path
        d="M129.95 3.55302C132.757 3.55302 134.824 5.45592 135.328 8.44244H124.271C124.883 5.41234 127.02 3.55302 129.95 3.55302ZM130.008 0.0348511C124.338 0.0348511 120.224 4.33161 120.224 10.2524C120.224 16.2574 124.201 20.4495 130.375 20.4495C135.756 20.4495 138.517 17.1173 138.517 17.1173L136.385 14.4068C136.385 14.4068 134.076 16.972 130.45 16.972C127.043 16.972 124.737 14.9762 124.21 11.7253H139.186C139.186 11.7253 139.294 10.9351 139.294 10.1739V10.171C139.256 4.20378 135.436 0.0348511 130.008 0.0348511Z"
        fill={fill || 'currentColor'}
      />
      <path
        d="M151.653 3.55302C154.46 3.55302 156.527 5.45592 157.031 8.44244H145.974C146.586 5.41234 148.723 3.55302 151.653 3.55302ZM151.714 0.0348511C146.044 0.0348511 141.929 4.33161 141.929 10.2524C141.929 16.2574 145.907 20.4495 152.081 20.4495C157.462 20.4495 160.222 17.1173 160.222 17.1173L158.091 14.4068C158.091 14.4068 155.782 16.972 152.156 16.972C148.749 16.972 146.443 14.9762 145.916 11.7253H160.892C160.892 11.7253 161 10.9351 161 10.1739V10.171C160.959 4.20378 157.142 0.0348511 151.714 0.0348511Z"
        fill={fill || 'currentColor'}
      />
      <path
        d="M26.9474 16.2748C26.9474 20.9812 23.8025 24.9439 19.4986 26.2106C18.2872 26.7684 17.2709 27.3261 16.7322 28.4446H16.406C15.8702 27.3349 14.8656 26.78 13.6688 26.2251C9.34161 24.9788 6.17338 20.9987 6.17338 16.2748C6.17338 10.5516 10.8238 5.91207 16.5604 5.91207C22.297 5.91207 26.9474 10.5516 26.9474 16.2748ZM33.1441 16.5944C33.1441 7.46343 25.7244 0.0610352 16.572 0.0610352C7.4197 0.0610352 0 7.46343 0 16.5944C0 24.8742 6.10058 31.7333 14.0619 32.9419C15.4102 33.2324 16.4963 34.3799 16.1906 35.0278C15.2267 36.3525 12.5768 40.2135 12.5768 42.6713C12.5768 44.8676 14.0706 47.0029 16.5604 47.0029C19.0501 47.0029 20.544 44.8676 20.544 42.6713C20.544 40.2484 17.964 36.46 16.9681 35.083C16.5808 34.4438 17.7048 33.2178 19.1142 32.939C19.1229 32.936 19.1287 32.9331 19.1375 32.9331C27.0726 31.7013 33.1441 24.8567 33.1441 16.5944Z"
        fill={fill || 'currentColor'}
      />
    </g>
  </svg>
)
