import { Card, Loader, NativeSelect, TextInput, createTheme } from '@mantine/core'
import { IconChevronDown } from '@tabler/icons-react'

import colorVariables from 'src/styles/Colors.module.scss'

/**
 * mantine forces to use 10 shades per color, even if only some of them are used in our design system.
 * by using this placeholder in bright pink, we should spot unintentional color picks by mantine and
 * be able to replace them consciously.
 */
const colorPlaceholder = '#ff00ff'

/**
 * @see: https://mantine.dev/theming/theme-object/
 */
export const theme = createTheme({
  colors: {
    cta: [
      colorPlaceholder,
      colorPlaceholder,
      colorPlaceholder,
      colorPlaceholder,
      colorPlaceholder,
      colorPlaceholder,
      colorVariables['color-cta-primary'], // main
      colorVariables['color-cta-hovered'], // hover
      colorPlaceholder,
      colorPlaceholder,
    ],
    rainforest: [
      colorPlaceholder,
      colorPlaceholder,
      colorPlaceholder,
      colorPlaceholder,
      colorPlaceholder,
      colorPlaceholder,
      colorVariables['color-rainforest-primary'], // main
      colorVariables['color-rainforest-dark'], // hover
      colorPlaceholder,
      colorPlaceholder,
    ],
    colombia: [
      colorPlaceholder,
      colorPlaceholder,
      colorPlaceholder,
      colorPlaceholder,
      colorPlaceholder,
      colorPlaceholder,
      colorVariables['color-colombia-primary'], // main
      colorVariables['color-colombia-dark'], // hover
      colorPlaceholder,
      colorPlaceholder,
    ],
    grey: [
      colorVariables['color-accent-italian'],
      colorVariables['color-accent-french'],
      colorVariables['color-accent-vienna'],
      colorVariables['color-accent-fullcity'],
      colorVariables['color-accent-city'],
      colorVariables['color-accent-american'],
      colorVariables['color-accent-newengland'],
      colorVariables['color-accent-cinnamon'],
      colorVariables['color-accent-drying'],
      colorVariables['color-accent-fresh'],
    ],
  },

  primaryColor: 'cta',

  fontFamily: 'Inter, Arial, Helvetica, Verdana, sans-serif',

  fontSizes: {
    xxs: '13px',
    xs: '14px',
    sm: '16px',
    md: '17px',
    lg: '18px',
    xl: '22px',
  },

  headings: {
    fontFamily: '"Noe Display", Times, Georgia, serif',
  },

  defaultRadius: 'xs',

  breakpoints: {
    sm: '36em',
    md: '48em',
    lg: '62em',
    xl: '75em',
  },

  components: {
    TextInput: TextInput.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    Card: Card.extend({
      defaultProps: {
        padding: 'md',
        radius: 'sm',
        withBorder: true,
      },
    }),
    Loader: Loader.extend({
      defaultProps: {
        size: 'lg',
      },
    }),
    NativeSelect: NativeSelect.extend({
      defaultProps: {
        size: 'md',
        rightSection: <IconChevronDown />,
      },
    }),
  },
})
