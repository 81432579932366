import { IconStar, IconStarFilled, IconStarHalfFilled } from '@tabler/icons-react'
import clsx from 'clsx'

import styles from './Rating.module.scss'

import type { ReactElement } from 'react'

interface RatingProps {
  rating: number
  size?: 'sm' | 'md' | 'lg'
}

export const Rating = ({ rating, size = 'md' }: RatingProps) => {
  const ratingFloor = Math.floor(rating)
  const ratingCeil = Math.ceil(rating)

  // eslint-disable-next-line no-nested-ternary
  const starSize = size === 'lg' ? 28 : size === 'md' ? 18 : 16

  return (
    <span
      className={clsx({
        [styles.rating]: true,
        [styles.empty]: rating < 0.5,
      })}
      data-count={ratingCeil}
    >
      {[1, 2, 3, 4, 5].map((number) => getIcon(number, rating, ratingFloor, ratingCeil, starSize, number))}
    </span>
  )
}

const getIcon = (
  starNumber: number,
  rating: number,
  ratingFloor: number,
  ratingCeil: number,
  starSize: number,
  key: number,
): ReactElement => {
  if (starNumber <= rating) return <IconStarFilled size={starSize} className={styles.star} key={key} />
  if (starNumber > ratingFloor && starNumber <= ratingCeil)
    return <IconStarHalfFilled size={starSize} className={styles.star} key={key} />
  return <IconStar size={starSize} className={styles.star} key={key} />
}
