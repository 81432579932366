import { useLocale } from 'src/contexts/LocaleContext'

import type { HTMLAttributes } from 'react'
import type { MoneyV2, UnitPriceMeasurement, Maybe } from 'src/data/graphql-generated'

interface PriceProps extends HTMLAttributes<HTMLSpanElement> {
  price: MoneyV2
  unitPriceMeasurement?: Maybe<UnitPriceMeasurement>
}

export const Price = ({ price: { amount, currencyCode }, unitPriceMeasurement, ...rest }: PriceProps) => {
  const { locale } = useLocale()
  const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode })
  const formattedPrice = formatter.format(parseFloat(amount))

  const hasUnits = unitPriceMeasurement?.referenceUnit
  const textToDisplay = hasUnits
    ? `${formattedPrice}/${unitPriceMeasurement?.referenceUnit?.toLowerCase()}`
    : formattedPrice

  return (
    <span {...rest} suppressHydrationWarning>
      {textToDisplay}
    </span>
  )
}
