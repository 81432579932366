import { Trans, useTranslation } from 'react-i18next'

import { Headline } from 'src/components/Headline/Headline'
import { Link } from 'src/components/Link/Link'
import { Rating } from 'src/components/Rating/Rating'
import { Text } from 'src/components/Text/Text'
import trustedShopSummary from 'src/data/_static/trustedShopsSummary.json'

import footerStyles from './Footer.module.scss'
import styles from './TrustElements.module.scss'

export const TrustElements = () => {
  const { t } = useTranslation()
  const { totalReviewCount, overallMark } = trustedShopSummary

  return (
    <div className={footerStyles.section}>
      <Headline variant="h4">{t('footer.green.trust.headline')}</Headline>
      <div>
        <div className={styles.ratingsSectionWrapper}>
          <div className={styles.ratingSummaryWrapper}>
            <Rating rating={overallMark > 4.5 ? 5 : overallMark} size="sm" />{' '}
            <Text bold small>
              {overallMark} {t('footer.green.trust.ratingToWord')}
            </Text>
          </div>
          <div>
            <Text small>
              <Trans
                i18nKey="footer.green.trust.numberOfRatings"
                values={{ totalRatings: totalReviewCount }}
                components={{ bold: <strong /> }}
              />
            </Text>
          </div>
        </div>
        <div className={styles.images}>
          <Link
            href="https://trustedshops.com/bewertung/info_X53E9A3A77C252488E0554D967A22BB23.html"
            target="_blank"
            aria-label="trusted shops"
          >
            <img
              src="/assets/img/footer/trust-elements/trusted-logo.webp"
              alt="trusted shops logo"
              title="trusted shops logo"
              width="100"
              height="40"
              loading="lazy"
            />
          </Link>
          <Link
            href={t('footer.green.trust.bCorpLink')}
            target="_blank"
            aria-label={`b-corp info - ${t('common.newTab')}`}
          >
            <img
              src="/assets/img/footer/trust-elements/b-corp-logo.webp"
              alt="b corp logo"
              title="b corp logo"
              width="120"
              height="58"
              loading="lazy"
            />
          </Link>
          <Link href={t('footer.green.trust.scaLink')} target="_blank" aria-label={`sca info - ${t('common.newTab')}`}>
            <img
              src="/assets/img/footer/trust-elements/sca-award-logo.webp"
              alt="sca award logo"
              title="sca award logo"
              width="42"
              height="54"
              loading="lazy"
            />
          </Link>
        </div>
      </div>
    </div>
  )
}
