import clsx from 'clsx'
import { cloneElement } from 'react'

import styles from './MobileRectangleButton.module.scss'

import type { ReactElement } from 'react'

interface MobileRectangleButtonProps {
  title: string
  url: string
  icon: ReactElement
  b2b?: boolean
}

export const MobileRectangleButton = ({ title, url, icon, b2b }: MobileRectangleButtonProps) => (
  <li className={clsx(styles.item, b2b && styles.b2b)}>
    <a href={url} className={clsx(styles.link, b2b && styles.b2b)}>
      {cloneElement(icon, {
        className: clsx(styles.icon, b2b && styles.b2b),
      })}
      {title}
    </a>
  </li>
)
