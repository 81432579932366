import { useTranslation } from 'react-i18next'

import { PerkInformation } from 'src/components/Perks/PerkInformation'
import { PerkProgress } from 'src/components/Perks/PerkProgress'
import { Text } from 'src/components/Text/Text'
import { useCartQuery } from 'src/hooks/useCartQuery'

import styles from './Perks.module.scss'

export const Perks = () => {
  const {
    computed: { hasShippingCosts, isFreeShipping, freeShippingPercentage, freeShippingRemaining },
  } = useCartQuery()
  const { t } = useTranslation()

  const headlineText = isFreeShipping ? t('cart.perks.headline.free') : t('cart.perks.headline.incentive')

  return hasShippingCosts ? (
    <div data-testid="perks-bar">
      <Text variant="p" bold className={styles.headLine}>
        {headlineText}
      </Text>
      <PerkProgress percentage={freeShippingPercentage} />
      <PerkInformation perkReached={isFreeShipping} amountRemaining={freeShippingRemaining} />
    </div>
  ) : null
}
