import type { IconProps } from '@tabler/icons-react'

interface CustomIconProps extends IconProps {
  fill?: string
}

export const IconDrip = ({ size = 32, title, fill, ...rest }: CustomIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill || 'currentColor'}
    {...(rest as any)}
  >
    {title && <title>{title}</title>}
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8654 6.14408C10.8654 7.3472 10.0642 8.36019 8.96768 8.684C8.65905 8.82659 8.40013 8.96918 8.26288 9.25511H8.17979C8.04328 8.97141 7.78733 8.82956 7.48242 8.68771C6.37997 8.36911 5.5728 7.35165 5.5728 6.14408C5.5728 4.68102 6.75759 3.49499 8.21911 3.49499C9.68063 3.49499 10.8654 4.68102 10.8654 6.14408ZM12.4442 6.22651C12.4442 3.89231 10.5538 2 8.22208 2C5.89033 2 4 3.89231 4 6.22651C4 8.34311 5.55425 10.0965 7.58257 10.4055C7.92607 10.4798 8.20279 10.7731 8.12489 10.9387C7.87933 11.2774 7.20421 12.2644 7.20421 12.8927C7.20421 13.4541 7.5848 14 8.21911 14C8.85343 14 9.23401 13.4541 9.23401 12.8927C9.23401 12.2733 8.5767 11.3049 8.32298 10.9528C8.22431 10.7895 8.51067 10.476 8.86975 10.4048C8.87197 10.404 8.87346 10.4033 8.87568 10.4033C10.8973 10.0876 12.4442 8.33791 12.4442 6.22651Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  </svg>
)
