import { Trans, useTranslation } from 'react-i18next'

import { Headline } from 'src/components/Headline/Headline'
import { Link } from 'src/components/Link/Link'
import { Text } from 'src/components/Text/Text'

import styles from './Footer.module.scss'

export const MissionStatement = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.section}>
      <Headline variant="h4" className={styles.headline}>
        {t('footer.green.mission.headline')}
      </Headline>
      <div className={styles.missionTextWrapper}>
        <Text small>
          <Trans
            i18nKey="footer.green.mission.text"
            components={[
              <Link
                key="contact-form-link"
                href={t('footer.green.mission.link')}
                inverted
                target="_blank"
                aria-label={`${t('footer.green.mission.headline')} - ${t('common.newTab')}`}
              />,
            ]}
          />
        </Text>
      </div>
    </div>
  )
}
