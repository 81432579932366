import { useMediaQuery } from '@mantine/hooks'
import { IconCertificate, IconCoffee, IconHelpCircle, IconUser } from '@tabler/icons-react'
import clsx from 'clsx'
import { useStateMachine } from 'little-state-machine'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconDrip } from 'src/components/Icons/IconDrip'
import { MobileMinicart } from 'src/components/Minicart/MobileMinicart/MobileMinicart'
import { Search } from 'src/components/Search/Search'
import { config } from 'src/config'
import { disableScroll, iosScrollLock } from 'src/functions/util/disableScroll'
import { useCartQuery } from 'src/hooks/useCartQuery'
import { MENU_NAVBAR, MENU_MOBILE_WRAPPER } from 'src/selectors'

import { DesktopGreenBar } from './Desktop/DesktopGreenBar'
import { LogoLink } from './DesktopAndMobile/LogoLink'
import { MenuItem } from './DesktopAndMobile/MenuItem'
import { MinicartButton } from './DesktopAndMobile/MinicartButton'
import { MobileHamburgerButton } from './Mobile/MobileHamburgerButton'
import { MobileRectangleButton } from './Mobile/MobileRectangleButton'
import styles from './Navigation.module.scss'

import type { ComponentType, MouseEvent } from 'react'
import type { MinicartProps } from 'src/components/Minicart/Minicart'
import type { MenuItem as MenuItemType } from 'src/data/graphql-generated'

const NAVBAR_OFFSET_MOBILE = 60

interface NavigationProps {
  navbarData: MenuItemType[]
}

const Minicart: ComponentType<MinicartProps> = dynamic(
  () => import('src/components/Minicart/Minicart').then((mod) => mod.Minicart),
  { ssr: false },
)

export const Navigation = ({ navbarData }: NavigationProps) => {
  const { t } = useTranslation()
  const { asPath } = useRouter()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [openSubMenus, setOpenSubMenus] = useState<{ [hash: string]: boolean }>({})
  const {
    state: { showMinicart },
  } = useStateMachine()

  const [isMinicartOpen, setIsMinicartOpen] = useState(showMinicart)
  const [isMinicartRenderRequested, setIsMinicartRenderRequested] = useState(false)

  const { data: cart } = useCartQuery()
  const isMobile = useMediaQuery(`(max-width: ${config.breakpoints.md}px)`)

  const openMenu = (allowToOpen = true) => {
    let tempIsMobileMenuOpen = !isMobileMenuOpen
    if (!allowToOpen) tempIsMobileMenuOpen = false

    disableScroll(tempIsMobileMenuOpen, 'mobile')
    disableScroll(tempIsMobileMenuOpen, 'tablet')

    // this is just for mobile iOs resize issues when the navigation bar from Safari is visible
    // more info is in the helper function comments
    if (!isMobileMenuOpen) {
      iosScrollLock(document.getElementById('navMainWrapper') as HTMLElement, NAVBAR_OFFSET_MOBILE)
    }

    setIsMobileMenuOpen(tempIsMobileMenuOpen)
  }

  const toggleSubMenu = (expandable: MenuItemType, e: MouseEvent) => {
    if (!expandable || !isMobile) {
      return
    }

    if (e) {
      e.preventDefault()
    }

    setOpenSubMenus({ ...openSubMenus, [expandable.id]: !openSubMenus[expandable.id] })
  }

  const toggleMinicart = (state: boolean) => {
    if (!isMinicartRenderRequested) {
      setIsMinicartRenderRequested(true)
    }

    setIsMinicartOpen(state)
  }

  const quantityInfoBubble = cart?.totalQuantity || 0
  const isOnCartPage = asPath.includes('/checkout/')
  const shouldRenderMinicart =
    (isMinicartRenderRequested || showMinicart) && cart && !isOnCartPage && quantityInfoBubble > 0

  return (
    <header className={styles.mainHeader}>
      {/* green bar */}
      <div className={styles.navbar} data-cy={MENU_NAVBAR}>
        <div className={styles.leftSideIconsWrapper}>
          <MobileHamburgerButton openMenu={openMenu} isMobileMenuOpen={isMobileMenuOpen} />
          <Search openMenu={openMenu} isMenuOpen={isMobileMenuOpen} />
        </div>
        <LogoLink className={styles.mobileLogo} />
        <DesktopGreenBar />
        <MinicartButton setIsMinicartOpen={toggleMinicart} />
      </div>

      {/* desktop black / mobile green bar */}
      <nav className={clsx(styles.mainWrapper, isMobileMenuOpen && styles.isMobileOpen)} id="navMainWrapper">
        <div className={styles.main} data-cy={MENU_MOBILE_WRAPPER}>
          <LogoLink className={styles.desktopLogo} />

          <ul className={styles.mobileRectangleButtons}>
            <MobileRectangleButton
              title={t('navbar.mobileTopbar.firstItem.title')}
              url={t('navbar.mobileTopbar.firstItem.url')}
              icon={<IconCoffee />}
            />
            <MobileRectangleButton
              title={t('navbar.mobileTopbar.secondItem.title')}
              url={t('navbar.mobileTopbar.secondItem.url')}
              icon={<IconUser />}
            />
            <MobileRectangleButton
              title={t('navbar.mobileTopbar.thirdItem.title')}
              url={t('navbar.mobileTopbar.thirdItem.url')}
              icon={<IconHelpCircle />}
            />
            <MobileRectangleButton
              title={t('navbar.mobileTopbar.fourthItem.title')}
              url={t('navbar.mobileTopbar.fourthItem.url')}
              icon={<IconDrip size={40} fill="white" />}
            />
          </ul>

          <ul className={styles.mainMenuWrapper}>
            {navbarData.map((item) => (
              <MenuItem key={item.id} item={item} openSubMenus={openSubMenus} toggleSubMenu={toggleSubMenu} />
            ))}
          </ul>

          <ul>
            <MobileRectangleButton
              title={t('navbar.b2b.ctaText')}
              url={t('navbar.b2b.ctaUrl')}
              icon={<IconCertificate />}
              b2b
            />
          </ul>
        </div>
      </nav>

      {shouldRenderMinicart && <Minicart isCartButtonHovered={isMinicartOpen} />}

      <MobileMinicart />
    </header>
  )
}
