import { IconShoppingBag } from '@tabler/icons-react'

import styles from './ShoppingCartIcon.module.scss'

interface ShoppingCartIconProps {
  quantity?: number
}

export const ShoppingCartIcon = ({ quantity = 0 }: ShoppingCartIconProps) => (
  <div style={{ position: 'relative' }}>
    <IconShoppingBag size={30} className={styles.icon} />
    <span className={styles.quantity}>{quantity}</span>
  </div>
)
