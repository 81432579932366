import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { useTranslation } from 'react-i18next'

import { Container } from 'src/components/Container/Container'
import { Link } from 'src/components/Link/Link'
import { Text } from 'src/components/Text/Text'

import styles from './SiteFooter.module.scss'

const LanguageSwitch = dynamic(() => import('src/components/Footer/LanguageSwitch').then((mod) => mod.LanguageSwitch))

export const SiteFooter = () => {
  const { t } = useTranslation()

  const copyright = `© ${new Date().getFullYear()} Circle Products GmbH`

  return (
    <div className={styles.wrapper}>
      <Container className={styles.container}>
        <div className={styles.section}>
          <Text small suppressHydrationWarning>
            {copyright}
          </Text>
        </div>
        <ul className={clsx(styles.section, styles.links)}>
          <li className={styles.link}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link href="#" onClick={() => window.Cookiebot.renew()} inverted role="button">
              {t('footer.legal.cookies.title')}
            </Link>
          </li>
          <li className={styles.link}>
            <Link href={t('footer.legal.imprint.link')} inverted>
              {t('footer.legal.imprint.title')}
            </Link>
          </li>
          <li className={styles.link}>
            <Link href={t('footer.legal.privacy.link')} inverted>
              {t('footer.legal.privacy.title')}
            </Link>
          </li>
          <li className={styles.link}>
            <Link href={t('footer.legal.conditions.link')} inverted>
              {t('footer.legal.conditions.title')}
            </Link>
          </li>
          <li className={styles.link}>
            <Link href={t('footer.legal.help.link')} inverted>
              {t('footer.legal.help.title')}
            </Link>
          </li>
          <LanguageSwitch />
        </ul>
      </Container>
    </div>
  )
}
