import clsx from 'clsx'

import styles from './Container.module.scss'

import type { HTMLAttributes } from 'react'

interface ContainerProps extends HTMLAttributes<HTMLElement> {
  className?: string
  wide?: boolean
  extra?: boolean
  columns?: '2' | '3' | string
}

export const Container = ({ className, wide, extra, columns, children, ...rest }: ContainerProps) => (
  <div
    className={clsx(styles.container, className, {
      [styles.wide]: wide,
      [styles.extra]: extra,
      [styles.twocolumns]: columns === '2',
      [styles.threecolumns]: columns === '3',
    })}
    {...rest}
  >
    {children}
  </div>
)
