import { MantineProvider } from '@mantine/core'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { StateMachineProvider } from 'little-state-machine'
import Head from 'next/head'
import Script from 'next/script'
import { DefaultSeo } from 'next-seo'
import { useEffect, useState } from 'react'

import { AnnouncementBar } from 'src/components/AnnouncementBar/AnnouncementBar'
import { Footer } from 'src/components/Footer/Footer'
import { Navigation } from 'src/components/Navigation/Navigation'
import { config } from 'src/config'
import { LocaleContextProvider } from 'src/contexts/LocaleContext'
import navbarRawData from 'src/data/_static/navbar.json'
import { queryClient } from 'src/data/queryClient'
import { Language } from 'src/enum/Language'
import { getLocaleFromLanguage } from 'src/functions/i18n/localeFns'
import { gtmScript } from 'src/functions/util/trackersAndScripts'
import { i18n } from 'src/i18n/i18n'
import { loadSentry } from 'src/lib/loadSentry'
import { theme } from 'src/theme'

import type { AppProps } from 'next/app'
import type { MenuItem } from 'src/data/graphql-generated'

import '@mantine/core/styles.css'
import 'src/styles/main.scss'
import 'src/store/store'

const CustomApp = ({ Component, pageProps, router }: AppProps) => {
  const { query, route } = router
  const lang = (query.lang || Language.de) as Language

  const locale = getLocaleFromLanguage(lang)
  const navbarData = navbarRawData[locale] as MenuItem[]

  const [userInteracted, setUserInteracted] = useState(false)
  const userInteractedTrigger = () => {
    setUserInteracted(true)
  }

  useEffect(() => {
    document.addEventListener('focus', userInteractedTrigger, { once: true })
    document.addEventListener('mousemove', userInteractedTrigger, { once: true })
    document.addEventListener('scroll', userInteractedTrigger, { once: true })
    document.addEventListener('touchstart', userInteractedTrigger, { once: true })
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      loadSentry()
    }
  }, [])

  i18n.changeLanguage(lang)

  return (
    <QueryClientProvider client={queryClient}>
      <StateMachineProvider>
        <LocaleContextProvider locale={locale}>
          <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          </Head>
          <DefaultSeo {...config.nextSeo} />

          {userInteracted && (
            <Script id="google-tag-manager" strategy="afterInteractive">
              {gtmScript}
            </Script>
          )}

          <MantineProvider theme={theme}>
            <Navigation navbarData={navbarData} />
            <main>
              <Component {...pageProps} />
              {route !== '/404' && <AnnouncementBar />}
            </main>
            <Footer />
          </MantineProvider>
        </LocaleContextProvider>
      </StateMachineProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default CustomApp
