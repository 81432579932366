import { IconMenu2, IconX } from '@tabler/icons-react'
import clsx from 'clsx'

import { MENU_MOBILE_HAMBURGER } from 'src/selectors'

import styles from './MobileHamburgerButton.module.scss'

interface MobileHamburgerButtonProps {
  openMenu: () => void
  isMobileMenuOpen: boolean
}

export const MobileHamburgerButton = ({ openMenu, isMobileMenuOpen }: MobileHamburgerButtonProps) => (
  <button
    type="button"
    aria-label="Menü"
    title="Menü"
    className={clsx('btn-reset', styles.hamburgerButton)}
    onClick={openMenu}
    data-cy={MENU_MOBILE_HAMBURGER}
  >
    {isMobileMenuOpen ? <IconX size={28} stroke={1.6} /> : <IconMenu2 size={28} />}
  </button>
)
