import { GraphQLClient } from 'graphql-request'

const SHOPIFY_API_PATH = `${process.env.NEXT_PUBLIC_SHOPIFY_CHECKOUT_URL}/api/${process.env.SHOPIFY_STOREFRONT_API_VERSION}/graphql`

export const graphQLClient = new GraphQLClient(SHOPIFY_API_PATH || '', {
  headers: {
    'Accept': 'application/json',
    'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_STOREFRONT_TOKEN || '',
  },
})
