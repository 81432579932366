import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import stylesGreenBar from 'src/components/Navigation/Desktop/DesktopGreenBar.module.scss'
import { ShoppingCartIcon } from 'src/components/ShoppingCartIcon/ShoppingCartIcon'
import { useLocale } from 'src/contexts/LocaleContext'
import { useCartQuery } from 'src/hooks/useCartQuery'

import styles from './MinicartButton.module.scss'

interface MinicartButtonProps {
  setIsMinicartOpen: (b: boolean) => void
}

export const MinicartButton = ({ setIsMinicartOpen }: MinicartButtonProps) => {
  const { t } = useTranslation()
  const { language } = useLocale()
  const { data: cart } = useCartQuery()

  const navbarCartLabel = t('navbar.shoppingCart')

  return (
    <div
      className={clsx(stylesGreenBar.item, styles.minicartButton)}
      onMouseEnter={() => setIsMinicartOpen(true)}
      onMouseLeave={() => setIsMinicartOpen(false)}
      data-testid="minicart-button"
    >
      <a href={`/${language}/checkout/cart`} className={styles.link} aria-label={navbarCartLabel}>
        <ShoppingCartIcon quantity={cart?.totalQuantity} />
        <span className={styles.label}>{navbarCartLabel}</span>
      </a>
    </div>
  )
}
