export function disableScroll(disabled: boolean = false, deviceType: 'mobile' | 'tablet' | 'all' = 'all'): void {
  const [body] = document.getElementsByTagName('body')
  if (disabled) body.classList.add(`no-scroll--${deviceType}`)
  else body.classList.remove(`no-scroll--${deviceType}`)
}

export function iosScrollLock(element: HTMLElement, offset: number): void {
  const iOS =
    /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  if (!iOS) return

  // this function disables smooth vertical swipe, but it only triggers on opened element.
  // for example you can not swipe down to reload page in chrome. However swipe is still smooth because css rule `-webkit-overflow-scrolling: touch;`
  function disableRubberBand(e: TouchEvent, currentClientY: number, overlayEl: Element): void {
    const clientY = e.targetTouches[0].clientY - currentClientY
    if (overlayEl.scrollTop === 0 && clientY > 0) e.preventDefault()

    const isOverlayTotallyScrolled = overlayEl.scrollHeight - overlayEl.scrollTop <= overlayEl.clientHeight
    if (isOverlayTotallyScrolled && clientY < 0) e.preventDefault()
  }

  let clientY: number
  element.addEventListener('touchstart', (e: TouchEvent) => {
    // eslint-disable-next-line prefer-destructuring
    if (e.targetTouches.length === 1) clientY = e.targetTouches[0].clientY
  })
  element.addEventListener('touchmove', (e: TouchEvent) => {
    if (e.targetTouches.length === 1) disableRubberBand(e, clientY, element)
  })

  // calculate view height
  let windowHeight: number = window.innerHeight
  element.style.height = `${windowHeight - offset}px`

  window.addEventListener('resize', () => {
    if (windowHeight !== window.innerHeight) {
      windowHeight = window.innerHeight
      element.style.height = `${windowHeight - offset}px`
    }
  })
}
