import clsx from 'clsx'

import type { HTMLAttributes } from 'react'

interface HeadlineProps extends HTMLAttributes<HTMLHeadingElement | HTMLDivElement> {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'sub'
  className?: string
}

export const Headline = ({ variant, children, className, ...rest }: HeadlineProps) => {
  const HeadlineTag = variant === 'sub' ? 'div' : variant

  return (
    <HeadlineTag className={clsx(variant, className)} {...rest}>
      {children}
    </HeadlineTag>
  )
}
