import { createContext, useContext, useMemo } from 'react'

import { config } from 'src/config'
import { getLanguageFromLocale } from 'src/functions/i18n/localeFns'

import type { ReactNode } from 'react'
import type { LanguageCode } from 'src/data/graphql-generated'
import type { Locale } from 'src/enum/Locale'

const defaultLocale = config.defaultLocales.canonical as Locale
const defaultLanguage = getLanguageFromLocale(defaultLocale)

const LocaleContext = createContext({
  locale: defaultLocale,
  language: defaultLanguage,
  langCode: defaultLanguage.toUpperCase() as LanguageCode,
})

interface LocaleContextProps {
  locale: Locale
  children: ReactNode
}
export const LocaleContextProvider = ({ locale, children }: LocaleContextProps) => {
  const value = useMemo(() => {
    const language = getLanguageFromLocale(locale)
    const langCode = language.toUpperCase() as LanguageCode
    return { locale, language, langCode }
  }, [locale])

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
}

export const useLocale = () => useContext(LocaleContext)
