import { IconCertificate, IconCoffee, IconHelpCircleFilled, IconUser } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import { useLocale } from 'src/contexts/LocaleContext'
import { Locale } from 'src/enum/Locale'

import styles from './DesktopGreenBar.module.scss'
import { GreenBarButton } from './GreenBarButton'

import type { GreenBarItem } from './GreenBarButton'

interface GreenNavbarCategoryType {
  help: GreenBarItem
  myAccount: GreenBarItem
}

interface GreenBarItemsType {
  [locale: string]: GreenNavbarCategoryType
}

const GREEN_BAR_ITEMS: GreenBarItemsType = {
  [Locale.deDE]: {
    help: {
      title: 'Hilfe',
      url: 'https://faq.coffeecircle.com',
      icon: <IconHelpCircleFilled size={18} />,
    },
    myAccount: {
      title: 'Mein Konto',
      url: `${process.env.NEXT_PUBLIC_SHOPIFY_CHECKOUT_URL}/account`,
      icon: <IconUser size={18} />,
    },
  },
  [Locale.enGB]: {
    help: {
      title: 'Help',
      url: 'https://faq.coffeecircle.com',
      icon: <IconHelpCircleFilled size={18} />,
    },
    myAccount: {
      title: 'My Account',
      url: `${process.env.NEXT_PUBLIC_SHOPIFY_CHECKOUT_URL}/en/account`,
      icon: <IconUser size={18} />,
    },
  },
}

export const DesktopGreenBar = () => {
  const { t } = useTranslation()
  const { locale } = useLocale()

  const { help, myAccount } = GREEN_BAR_ITEMS[locale]
  const cta: GreenBarItem = {
    title: `${t('navbar.topbar.ctaText')} ›`,
    url: t('navbar.topbar.ctaUrl'),
    icon: <IconCoffee size={18} />,
  }

  const b2b: GreenBarItem = {
    title: t('navbar.b2b.ctaText'),
    url: t('navbar.b2b.ctaUrl'),
    icon: <IconCertificate size={18} />,
  }

  return (
    <ul className={styles.greenBar}>
      <GreenBarButton item={cta} isCta />
      <GreenBarButton item={b2b} />
      <GreenBarButton item={help} />
      <GreenBarButton item={myAccount} />
    </ul>
  )
}
