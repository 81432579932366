import { usePathname } from 'next/navigation'

import { Container } from 'src/components/Container/Container'
import { PageFooter } from 'src/components/Footer/PageFooter'
import { SiteFooter } from 'src/components/Footer/SiteFooter'
import { SEOLinks } from 'src/components/SEOLinks/SEOLinks'
import { useLocale } from 'src/contexts/LocaleContext'

import styles from './Footer.module.scss'
import { SlimFooter } from './SlimFooter'

export const Footer = () => {
  const { locale } = useLocale()
  const pathname = usePathname()
  const isSlimFooter = pathname?.includes('/checkout/cart') || pathname?.includes('/account/')

  const awardTitle = locale === 'de-DE' ? 'Röster des Jahres 2024' : 'Roaster of the year 2024'
  const awardUrl = locale === 'de-DE' ? '/de/e/roester-des-jahres-2024' : '/en/e/roaster-of-the-year'

  return (
    <footer>
      {!isSlimFooter && <SEOLinks />}
      <div className={styles.footer}>
        <Container className={styles.container}>
          <div className={styles.award}>
            <a href={awardUrl} title={awardTitle}>
              <img
                className={styles.headerSvg}
                width="100"
                height="115.5"
                src="/assets/img/footer/crema-roaster-of-the-year-2024.png"
                alt={awardTitle}
              />
            </a>
          </div>
          {isSlimFooter ? <SlimFooter /> : <PageFooter />}
        </Container>
        <SiteFooter />
      </div>
    </footer>
  )
}
