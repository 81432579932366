/* eslint-disable max-len */

import { CoffeeCircleLogo } from 'src/components/CoffeeCircleLogo/CoffeeCircleLogo'
import { useLocale } from 'src/contexts/LocaleContext'
import { Language } from 'src/enum/Language'
import { Locale } from 'src/enum/Locale'

import type { HTMLProps } from 'react'

interface LogoLinkProps extends HTMLProps<HTMLAnchorElement> {}

export const LogoLink = ({ ...rest }: LogoLinkProps) => {
  const { locale } = useLocale()

  return (
    <a href={`/${locale === Locale.enGB ? Language.en : ''}`} aria-label="Home" {...rest}>
      <CoffeeCircleLogo />
    </a>
  )
}
