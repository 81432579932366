import { useTranslation } from 'react-i18next'

import { Headline } from 'src/components/Headline/Headline'
import { Link } from 'src/components/Link/Link'

import styles from './Footer.module.scss'

export const HelpAndFaq = () => {
  const { t } = useTranslation()

  const help = [
    {
      title: t('footer.green.helpAndFaq.shipping.title'),
      link: t('footer.green.helpAndFaq.shipping.link'),
    },
    {
      title: t('footer.green.helpAndFaq.returns.title'),
      link: t('footer.green.helpAndFaq.returns.link'),
    },
    {
      title: t('footer.green.helpAndFaq.repairs.title'),
      link: t('footer.green.helpAndFaq.repairs.link'),
    },
    {
      title: t('footer.green.helpAndFaq.faq.title'),
      link: t('footer.green.helpAndFaq.faq.link'),
    },
  ]

  return (
    <div className={styles.section}>
      <Headline variant="h4" className={styles.headline}>
        {t('footer.helpAndServiceHeadline')}
      </Headline>
      <ul className={styles.helpList}>
        {help.map((item) => (
          <li key={item.link} className={styles.helpLink}>
            <Link
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              inverted
              aria-label={`${item.title} - ${t('common.newTab')}`}
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
