/* eslint-disable no-underscore-dangle */
declare global {
  interface Window {
    __SENTRY__INITIALIZED__?: boolean
  }
}

export const loadSentry = async () => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production' && !window.__SENTRY__INITIALIZED__) {
    await import('./sentry.client.config')
    window.__SENTRY__INITIALIZED__ = true
  }
}
