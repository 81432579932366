import { useTranslation } from 'react-i18next'

import { Headline } from 'src/components/Headline/Headline'

import styles from './AvailablePayments.module.scss'
import footerStyles from './Footer.module.scss'

export const AvailablePayments = () => {
  const { t } = useTranslation()

  const providers = ['apple_pay', 'google_pay', 'klarna', 'paypal', 'mastercard', 'visa', 'ideal', 'bancontact', 'eps']

  return (
    <div className={footerStyles.section}>
      <Headline variant="h4" className={styles.headline}>
        {t('footer.green.payment.headline')}
      </Headline>
      <ul className={styles.paymentProviderList}>
        {providers.map((provider) => (
          <li key={provider}>
            <img
              src={`/assets/img/footer/payment/${provider}.webp`}
              alt={`Logo ${provider}`}
              title={`Logo ${provider}`}
              width="60"
              height="40"
              loading="lazy"
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
