import { QueryClient } from '@tanstack/react-query'

const queryClient = new QueryClient()

queryClient.setQueryDefaults(['subscription'], {
  gcTime: 0,
  staleTime: Infinity,
  retry: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
})

export { queryClient }
