import { IconSearch } from '@tabler/icons-react'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchBoxContainer } from './SearchBoxContainer'

import type { SearchAlgoliaProps } from './SearchAlgolia'
import type { ComponentType } from 'react'

interface SearchProps {
  openMenu: Function
  isMenuOpen: boolean
}

export const Search = ({ openMenu, isMenuOpen }: SearchProps) => {
  const { t } = useTranslation()
  const [isActive, setIsActive] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const SearchAlgolia: ComponentType<SearchAlgoliaProps> = dynamic(
    () => import('./SearchAlgolia').then((mod) => mod.SearchAlgolia),
    {
      ssr: false,
      loading: () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(
          () => () => {
            setIsLoaded(true)
          },
          [],
        )

        return null
      },
    },
  )

  return (
    <>
      {!isLoaded && (
        <SearchBoxContainer
          isMobileOpen={false}
          onMenuButton={() => {
            setIsActive(true)
          }}
        >
          <div className="ais-SearchBox">
            <div className="ais-SearchBox-form">
              <input
                type="search"
                placeholder={t('navbar.search.placeholderText')}
                className="ais-SearchBox-input"
                onFocus={() => {
                  setIsActive(true)
                }}
                aria-label={t('navbar.search.placeholderText')}
                title={t('navbar.search.placeholderText')}
                suppressHydrationWarning
              />
              <button type="submit" aria-label="Suchen" className="ais-SearchBox-submit">
                <IconSearch size={20} />
              </button>
            </div>
          </div>
        </SearchBoxContainer>
      )}
      {isActive && <SearchAlgolia openMenu={openMenu} isMenuOpen={isMenuOpen} />}
    </>
  )
}
