import { createStore } from 'little-state-machine'

declare module 'little-state-machine' {
  interface GlobalState {
    showMinicart: boolean
    isCartBusy: boolean
  }
}

export const store = createStore(
  {
    showMinicart: false,
    isCartBusy: false,
  },
  { persist: 'none' },
)
