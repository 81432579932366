import { CoffeeCircleLogo } from 'src/components/CoffeeCircleLogo/CoffeeCircleLogo'
import { AvailablePayments } from 'src/components/Footer/AvailablePayments'
import { HelpForOrders } from 'src/components/Footer/HelpForOrders'
import { TrustElements } from 'src/components/Footer/TrustElements'

import styles from './Footer.module.scss'

export const SlimFooter = () => (
  <>
    <div>
      <CoffeeCircleLogo fill="#FFF" />
    </div>
    <div className={styles.row}>
      <HelpForOrders />
      <AvailablePayments />
      <TrustElements />
    </div>
  </>
)
