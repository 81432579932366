import { Progress } from '@mantine/core'

import styles from './PerkProgress.module.scss'

interface PerkProgressProps {
  percentage: number
}

export const PerkProgress = ({ percentage }: PerkProgressProps) => {
  const barPercentage = Math.trunc(percentage < 1 ? percentage * 100 : 100)

  return (
    <Progress
      value={barPercentage}
      size="lg"
      radius="lg"
      transitionDuration={1000}
      classNames={{
        root: styles.root,
        section: styles.progressSection,
      }}
    />
  )
}
