import { useTranslation } from 'react-i18next'

import { AvailablePayments } from 'src/components/Footer/AvailablePayments'
import { HelpAndFaq } from 'src/components/Footer/HelpAndFaq'
import { LinksToSocial } from 'src/components/Footer/LinksToSocial'
import { MissionStatement } from 'src/components/Footer/MissionStatement'
import { ShippingPartners } from 'src/components/Footer/ShippingPartners'
import { TrustElements } from 'src/components/Footer/TrustElements'
import { Text } from 'src/components/Text/Text'

import styles from './Footer.module.scss'

export const PageFooter = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.row}>
        <HelpAndFaq />
        <MissionStatement />
        <TrustElements />
      </div>
      <div className={styles.row}>
        <AvailablePayments />
        <ShippingPartners />
        <LinksToSocial />
      </div>
      {t('footer.footnote') && (
        <div className={styles.row}>
          <Text x-small>{t('footer.footnote')}</Text>
        </div>
      )}
    </>
  )
}
