export const LOAD_MORE_POSTS = 'button.load_more_posts'
export const POST_CARD = 'post_card'
export const PRODUCT_CARD = 'product_card'

export const SCHEMA_ORGANIZATION = 'schema.organization'
export const SCHEMA_PRODUCT_CARD = 'schema.product.card'

export const HOMEPAGE = 'homepage'

export const HERO_BANNER = 'hero_banner'
export const PROMO_CARDS = 'promo_cards'
export const ILLUSTRATION_CARDS = 'illustration_cards'
export const IMAGE_TEXT_CONTAINER = 'image_text_container'
export const BRAND_VALUES = 'brand_values'

export const MENU_NAVBAR = 'menu.navbar'
export const MENU_MOBILE_HAMBURGER = 'menu.mobile.hamburger'
export const MENU_MOBILE_WRAPPER = 'menu.mobile.wrapper'
export const MENU_MOBILE_ITEM = 'menu.mobile.item'
export const MENU_MOBILE_SUBMENU = 'menu.mobile.submenu'

export const COFFEE_MATCH_STEP = 'coffee_match.step'
export const COFFEE_MATCH_CHOICE = 'coffee_match.choice'
export const COFFEE_MATCH_WRAPPER = 'coffee_match.wrapper'
export const COFFEE_MATCH_BACK_BUTTON = 'coffee_match.back_button'

export const CATEGORY_HEADER = 'category_header'
export const PRODUCT_FILTERS = 'product-filters'
