import clsx from 'clsx'

import styles from './Link.module.scss'

import type { HTMLProps } from 'react'

export interface LinkProps extends HTMLProps<HTMLAnchorElement> {
  inverted?: boolean
  className?: string
  underline?: boolean
  stealth?: boolean
}

export const Link = ({ inverted = false, className, children, underline = true, stealth, ...rest }: LinkProps) => (
  <a
    className={clsx(
      styles.linkBase,
      inverted && styles.inverted,
      !underline && styles.noUnderline,
      className,
      stealth && styles.stealth,
    )}
    {...rest}
  >
    {children}
  </a>
)
