import { IconBrandFacebook, IconBrandInstagram, IconBrandYoutube } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import { Headline } from 'src/components/Headline/Headline'

import styles from './Footer.module.scss'

export const LinksToSocial = () => {
  const { t } = useTranslation()

  const links = [
    {
      name: 'Instagram',
      icon: <IconBrandInstagram size={30} />,
      link: t('footer.social.instagram.link'),
    },
    {
      name: 'Facebook',
      icon: <IconBrandFacebook size={30} />,
      link: t('footer.social.facebook.link'),
    },
    {
      name: 'Youtube',
      icon: <IconBrandYoutube size={30} />,
      link: t('footer.social.youtube.link'),
    },
  ]

  return (
    <div className={styles.section}>
      <Headline variant="h4" className={styles.headline}>
        {t('footer.social.headline')}
      </Headline>
      <ul className={styles.socialIconsList}>
        {links.map((item) => (
          <li key={item.link}>
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialIcon}
              aria-label={`${item.name} - ${t('common.newTab')}`}
            >
              {item.icon}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
