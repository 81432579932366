import { IconSearch, IconX } from '@tabler/icons-react'
import clsx from 'clsx'

import styles from './SearchBoxContainer.module.scss'

import type { MouseEventHandler, ReactNode } from 'react'

interface SearchBoxContainerProps {
  isMobileOpen: boolean
  onMenuButton: MouseEventHandler<HTMLButtonElement>
  children: ReactNode
}

export const SearchBoxContainer = ({ isMobileOpen, onMenuButton, children }: SearchBoxContainerProps) => (
  <div className={clsx(styles.algoliaSearch, isMobileOpen && styles.isMobileOpen)}>
    <button type="button" aria-label="Suche" className={clsx(styles.menuButton, 'btn-reset')} onClick={onMenuButton}>
      <IconSearch className={styles.magnifier} size={25} />
      <IconX className={styles.close} size={28} stroke={1.6} />
    </button>
    <div className={styles.query}>
      <div className={styles.queryInner}>{children}</div>
    </div>
  </div>
)
