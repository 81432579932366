import { Button as MantineButton } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import clsx from 'clsx'
import { type ButtonHTMLAttributes, type AnchorHTMLAttributes, type ReactElement, cloneElement } from 'react'

import { config } from 'src/config'

import styles from './Button.module.scss'

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>,
    AnchorHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'primary-cta' | 'tertiary-cta'
  inverted?: boolean
  active?: boolean
  startIcon?: ReactElement
  endIcon?: ReactElement
  fullWidth?: boolean
  fullWidthMobile?: boolean
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  inline?: boolean
  component?: any
}

export const Button = ({
  variant = 'primary',
  inverted = false,
  active = false,
  className,
  startIcon,
  endIcon,
  href,
  fullWidth = false,
  fullWidthMobile = false,
  type = 'button',
  inline = false,
  children,
  component,
  ...rest
}: ButtonProps) => {
  const isMobile = useMediaQuery(`(max-width: ${config.breakpoints.md}px)`)

  return (
    <MantineButton
      className={clsx(
        styles.buttonBase,
        {
          [styles.primary]: variant === 'primary',
          [styles.secondary]: variant === 'secondary',
          [styles.tertiary]: variant === 'tertiary',
          [styles.ghost]: variant === 'ghost',
          [styles.primaryCta]: variant === 'primary-cta',
          [styles.tertiaryCta]: variant === 'tertiary-cta',
          [styles.inverted]: inverted,
          [styles.active]: active,
          [styles.inline]: inline,
        },
        className,
      )}
      classNames={styles}
      size="md"
      fullWidth={fullWidth || (fullWidthMobile && isMobile)}
      href={href || undefined}
      type={!href ? type : undefined}
      leftSection={startIcon && cloneElement(startIcon, { 'data-testid': 'start-icon' })}
      rightSection={endIcon && cloneElement(endIcon, { 'data-testid': 'end-icon' })}
      {...((component || href) && { component: href ? 'a' : component })}
      {...rest}
    >
      {children}
    </MantineButton>
  )
}
