import { Trans, useTranslation } from 'react-i18next'

import { Headline } from 'src/components/Headline/Headline'
import { Link } from 'src/components/Link/Link'
import { Text } from 'src/components/Text/Text'

import styles from './Footer.module.scss'

export const HelpForOrders = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.section}>
      <Headline variant="h4" className={styles.headline}>
        {t('footer.green.customerCare.headline')}
      </Headline>
      <Text small>
        <b>{t('footer.green.customerCare.content.contentHeadline')}</b>
      </Text>
      <br />
      <Text small>
        <Trans
          i18nKey="footer.green.customerCare.content.contactUsText"
          components={[
            <Link
              key="contact-form-link"
              href={t('footer.green.customerCare.content.contactUsLink')}
              inverted
              target="_blank"
              aria-label={`${t('footer.contactAndHelp')} - ${t('common.newTab')}`}
            />,
            <Link
              key="telephone-link"
              href={t('footer.green.customerCare.content.phoneLink')}
              inverted
              aria-label={`${t('footer.contactAndHelp')} - ${t('common.newTab')}`}
            />,
          ]}
        />
      </Text>

      <Text small variant="p">
        <Trans i18nKey="footer.green.customerCare.content.workingHours.monday" components={{ bold: <strong /> }} />
        <br />
        <Trans i18nKey="footer.green.customerCare.content.workingHours.rest" components={{ bold: <strong /> }} />
        <br />
      </Text>
    </div>
  )
}
