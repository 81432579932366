import clsx from 'clsx'

import styles from './Text.module.scss'

import type { HTMLAttributes } from 'react'

interface TextProps extends HTMLAttributes<HTMLElement> {
  'variant'?: 'p' | 'span' | 'blockquote' | 'intro'
  'small'?: boolean
  'x-small'?: boolean
  'bold'?: boolean
  'light'?: boolean
  'center'?: boolean
  'right'?: boolean
  'className'?: string
}

export const Text = ({
  variant = 'span',
  small = false,
  'x-small': xSmall = false,
  bold = false,
  light = false,
  center = false,
  right = false,
  className,
  children,
  ...rest
}: TextProps) => {
  const textCss = clsx(
    {
      'intro': variant === 'intro',
      small,
      'x-small': xSmall,
      bold,
      [styles.light]: light,
      [styles.center]: center,
      [styles.right]: right,
    },
    className,
  )

  const TextTag = variant === 'intro' ? 'p' : variant

  return (
    <TextTag className={textCss} {...rest}>
      {children}
    </TextTag>
  )
}
