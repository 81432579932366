import { Language } from 'src/enum/Language'
import { Locale } from 'src/enum/Locale'

import type { IsoLocale } from 'src/enum/IsoLocale'

export const getCountryFromLocale = (locale: Locale) =>
  String(locale)
    .substring(locale.length - 2)
    .toLowerCase()

export const getLocaleFromLanguage = (language: Language | string): Locale =>
  language === Language.en ? Locale.enGB : Locale.deDE

export const getLanguageFromLocale = (locale: Locale): Language =>
  String(locale).substring(0, 2).toLowerCase() as Language

export function getISOLocale(locale: Locale): IsoLocale {
  return locale.replace('-', '_') as IsoLocale
}

export function getUnicodeLocale(locale: IsoLocale): Locale {
  return locale.replace('_', '-') as Locale
}
