import { Fragment } from 'react'

import { Container } from 'src/components/Container/Container'
import { Headline } from 'src/components/Headline/Headline'
import { Link } from 'src/components/Link/Link'
import { Text } from 'src/components/Text/Text'
import { useLocale } from 'src/contexts/LocaleContext'
import footer from 'src/data/_static/footer.json'
import { chunk } from 'src/functions/util/chunk'

import styles from './SEOLinks.module.scss'

interface ShopifyFooterEntity {
  id: string
  title: string
  url: string
  items?: ShopifyFooterEntity[]
}

export const SEOLinks = () => {
  const { locale } = useLocale()

  const footerByLang = footer[locale] as ShopifyFooterEntity[]

  return (
    <>
      {footerByLang.map((footerSection, sectionIndex) => (
        <Fragment key={footerSection.id}>
          <Container className={styles.linksSectionContainer}>
            {footerSection.items?.map((footerSubSection) => {
              const sectionColumns = footerSection.items?.length || (1 as number)
              const columnsToChunkSize: Record<number, number> = {
                1: 8,
                2: 4,
                3: 12,
                4: 15,
              }
              const chunks = chunk(footerSubSection.items || [], columnsToChunkSize[sectionColumns] || 15)

              return (
                <div key={footerSubSection.id} className={styles.linksSubsectionContainer}>
                  <Headline variant="h4" className={styles.headline}>
                    {footerSubSection.title}
                  </Headline>

                  <div className={styles.sectionWrapper}>
                    {chunks.map((itemGroup: ShopifyFooterEntity[], index: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <ul className={styles.sectionLinksList} key={index}>
                        {itemGroup.map((link) => (
                          <li key={link.id}>
                            <Text small className={styles.textLink}>
                              <Link href={link.url}>{link.title}</Link>
                            </Text>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </div>
                </div>
              )
            })}
          </Container>
          {footerByLang.length !== sectionIndex + 1 && <hr />}
        </Fragment>
      ))}
    </>
  )
}
