import { IconCheck, IconGift } from '@tabler/icons-react'
import clsx from 'clsx'
import { Trans, useTranslation } from 'react-i18next'

import { Price } from 'src/components/Price/Price'
import { Text } from 'src/components/Text/Text'

import styles from './Perks.module.scss'

import type { MoneyV2 } from 'src/data/graphql-generated'

interface PerkInformationProps {
  perkReached: boolean
  amountRemaining: MoneyV2
}

export const PerkInformation = ({ perkReached, amountRemaining }: PerkInformationProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.perkInfoContainer}>
      <Text className={clsx(styles.perk, perkReached && styles.active)}>
        {perkReached ? (
          <>
            <span className={clsx(styles.perkIcon, styles.rounded)} data-testid="check-icon">
              <IconCheck stroke={1.6} />
            </span>

            <span>{t('cart.perks.freeShipmentPerkReached')}</span>
          </>
        ) : (
          <>
            <IconGift className={styles.perkIcon} />

            <span>
              <Trans
                i18nKey="cart.perks.remainingAmountForPerk"
                components={[<Price key="price" price={amountRemaining} className="strong" />, <strong key="strong" />]}
              />
            </span>
          </>
        )}
      </Text>
    </div>
  )
}
