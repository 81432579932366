import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import { Language } from 'src/enum/Language'
import de from 'src/i18n/de/translations.json'
import en from 'src/i18n/en/translations.json'

import type { i18n as i18nInstance } from 'i18next'

export const defaultNS = 'frontend-next'
export const defaultLanguage = Language.de

export const resources = { de } as const

const i18n: i18nInstance = i18next.createInstance().use(initReactI18next)

i18n.init({
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  defaultNS,
  resources: { de, en },
  returnObjects: true,
  react: { bindI18n: '' },
})

export { i18next, i18n }
