import { IconX } from '@tabler/icons-react'
import clsx from 'clsx'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'src/components/Button/Button'
import { Text } from 'src/components/Text/Text'
import { isPathAllowed } from 'src/functions/url/isPathAllowed'

import styles from './AnnouncementBar.module.scss'

const VISIBILITY_TRESHOLD_PIXELS = 600
const DISMISSED_BAR_COOKIE_NAME = 'AnnouncementBarDismissed'

export const AnnouncementBar = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const [mounted, setMounted] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const barActive = t('announcementBar.barActive') === 'yes'
  const showOnPages = t('announcementBar.showOnPages')
    .split(',')
    .map((url) => url.trim())
  const hideOnPages = t('announcementBar.hideOnPages')
    .split(',')
    .map((url) => url.trim())
  const barAllowed = isPathAllowed(router.asPath.replace(/\/$/, ''), showOnPages, hideOnPages)

  const onScroll = () => {
    const isBarDismissed = Cookies.get(DISMISSED_BAR_COOKIE_NAME)

    if (isBarDismissed) {
      return
    }

    // if at the bottom => hide => not to cover footer
    if (Math.ceil(window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
      setIsVisible(false)
      return
    }

    setIsVisible(window.pageYOffset > VISIBILITY_TRESHOLD_PIXELS)
  }

  useEffect(() => {
    setMounted(true)

    if (barActive && barAllowed) {
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [barActive, barAllowed])

  const pitchText = t('announcementBar.pitchText')
  const ctaText = t('announcementBar.ctaText')
  const linkURL = t('announcementBar.linkURL')
  const imgSRC = t('announcementBar.imgSRC')

  const hasImage = Boolean(imgSRC)

  const onDismiss = () => {
    Cookies.set(DISMISSED_BAR_COOKIE_NAME, '1', { expires: 1 })

    setIsVisible(false)
  }

  // We want this component to only be rendered on client-side. Otherwise, it causes a hydration error.
  // @see https://github.com/vercel/next.js/discussions/17443#discussioncomment-637879
  if (!mounted) {
    return null
  }

  if (!barActive || !barAllowed) {
    return null
  }

  return (
    <div
      className={clsx(styles.wrapper, isVisible && styles.visible)}
      role="dialog"
      aria-labelledby="ctaText"
      aria-describedby="pitchText"
    >
      <div className={styles.inner}>
        <div className={clsx(styles.imageAndPitchContainer, hasImage && styles.hasImage)}>
          {hasImage && <img src={imgSRC} alt={ctaText} className={styles.image} width="50" height="50" />}
          <Text variant="span" className={clsx(hasImage && styles.hasImage)} id="pitchText">
            {pitchText}
          </Text>
        </div>

        <div className={styles.ctaWrapper}>
          <Button href={linkURL} id="ctaText">
            {ctaText}
          </Button>
        </div>
      </div>

      <IconX className={styles.dismissIcon} onClick={onDismiss} stroke={1.6} />
    </div>
  )
}
