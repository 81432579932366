import { IconChevronRight, IconCircleCheck, IconShoppingBag } from '@tabler/icons-react'
import clsx from 'clsx'
import { useStateMachine } from 'little-state-machine'
import { useTranslation } from 'react-i18next'

import { Button } from 'src/components/Button/Button'
import { Perks } from 'src/components/Perks/Perks'
import { ShoppingCartIcon } from 'src/components/ShoppingCartIcon/ShoppingCartIcon'
import { Text } from 'src/components/Text/Text'
import { useLocale } from 'src/contexts/LocaleContext'
import { useCartQuery } from 'src/hooks/useCartQuery'

import styles from './MobileMinicart.module.scss'

export const MobileMinicart = () => {
  const {
    data: cart,
    computed: { hasShippingCosts },
  } = useCartQuery()
  const {
    state: { showMinicart },
  } = useStateMachine()
  const { language } = useLocale()
  const { t } = useTranslation()

  return (
    <div className={clsx(styles.container, showMinicart && styles.show)}>
      <div className={styles.successRow}>
        <div />
        <div className={styles.checkAndText}>
          <IconCircleCheck className={styles.checkIcon} />
          <Text bold className={styles.successText}>
            {t('navbar.minicart.itemAdded')}
          </Text>
        </div>

        {cart && <ShoppingCartIcon quantity={cart?.totalQuantity} />}
      </div>

      {hasShippingCosts && (
        <div className={styles.perksContainer}>
          <Perks />
        </div>
      )}

      <div>
        <Button
          variant="primary-cta"
          startIcon={<IconShoppingBag />}
          endIcon={<IconChevronRight />}
          fullWidth
          href={`/${language}/checkout/cart`}
        >
          {t('navbar.minicart.goToCart')}
        </Button>
      </div>
    </div>
  )
}
