import { useQuery } from '@tanstack/react-query'
import Cookies from 'js-cookie'

import { config } from 'src/config'
import { useLocale } from 'src/contexts/LocaleContext'
import { graphQLClient } from 'src/data/graphql/graphql-client'
import { GetCartByIdDocument, CurrencyCode, DiscountApplicationTargetType } from 'src/data/graphql-generated'

import type { Cart, MoneyV2 } from 'src/data/graphql-generated'

interface CartQueryResponse {
  isLoading: boolean
  data: Cart | undefined
  isFetching: boolean
  isFetched: boolean
  computed: {
    hasShippingCosts: boolean
    isFreeShipping: boolean
    freeShippingPercentage: number
    freeShippingRemaining: MoneyV2
    voucherDiscount: MoneyV2
    isLoggedIn: boolean
  }
}

export const useCartQuery = (): CartQueryResponse => {
  const { langCode } = useLocale()

  const cartId = Cookies.get('storefront_cart_id') ?? 'gid://shopify/Cart/null'

  const { isLoading, data, isFetching, isFetched } = useQuery({
    queryKey: ['cart'],
    queryFn: async () => {
      const { cart }: { cart: Cart } = await graphQLClient.request({
        document: GetCartByIdDocument,
        variables: { cartId, lang: langCode },
      })

      return cart
    },
    select: (cart) => {
      if (!cart) return undefined
      const { donationProductId } = config

      const donationLines = cart.lines.nodes.filter(({ merchandise }) => merchandise.product.id === donationProductId)
      const donationLinesCount = donationLines?.reduce((acc, line) => acc + line.quantity, 0)

      return {
        ...cart,
        totalQuantity: cart.totalQuantity - donationLinesCount,
      }
    },
    refetchOnMount: process.env.NODE_ENV === 'test',
  })

  const hasFreeShippingVoucher = data?.discountAllocations.some(
    ({ targetType }) => targetType === DiscountApplicationTargetType.ShippingLine,
  )
  const shippingAdjustedTotalAmount = getShippingAdjustedTotalAmount(data)
  const hasShippingCosts = shippingAdjustedTotalAmount.amount > 0
  const freeShippingPercentage = hasFreeShippingVoucher ? 1 : getFreeShippingPercentage(shippingAdjustedTotalAmount)
  const isFreeShipping = hasFreeShippingVoucher || freeShippingPercentage >= 1
  const freeShippingRemaining = hasFreeShippingVoucher
    ? { amount: 0, currencyCode: CurrencyCode.Eur }
    : getFreeShippingRemaining(shippingAdjustedTotalAmount)
  const voucherDiscount = getVoucherDiscount(data)

  const customerId = data?.buyerIdentity.customer?.id
  const isLoggedIn = !!customerId

  if (customerId) {
    ;(async () => {
      const { getCurrentScope } = await import('@sentry/browser')
      const scope = getCurrentScope?.()
      if (scope?.getClient()) {
        scope?.setUser({
          id: customerId.replace('gid://shopify/Customer/', ''),
        })
      } else {
        // eslint-disable-next-line no-console
        console.warn('Sentry client not initialized; skipping capture.')
      }
    })()
  }

  return {
    isLoading,
    data,
    isFetching,
    isFetched,
    computed: {
      hasShippingCosts,
      isFreeShipping,
      freeShippingPercentage,
      freeShippingRemaining,
      voucherDiscount,
      isLoggedIn,
    },
  }
}

const getShippingAdjustedTotalAmount = (cart: Cart | undefined): MoneyV2 => {
  if (!cart) return { amount: 0, currencyCode: CurrencyCode.Eur }

  const {
    cost: { subtotalAmount },
    lines: { nodes: items },
  } = cart
  const { shippingIgnoredProductIds } = config

  const subtractedAmount = items.reduce((sum, item) => {
    if (shippingIgnoredProductIds.includes(item.merchandise.product.id)) {
      return sum + parseFloat(item.cost.totalAmount.amount)
    }
    return sum
  }, 0)

  return { amount: subtotalAmount.amount - subtractedAmount, currencyCode: subtotalAmount.currencyCode }
}

export const getFreeShippingPercentage = (totalAmount: MoneyV2): number => {
  const { shippingFreeFrom } = config
  return parseFloat(totalAmount.amount) / shippingFreeFrom
}

export const getFreeShippingRemaining = (totalAmount: MoneyV2): MoneyV2 => {
  const { shippingFreeFrom } = config
  const remainingAmount = shippingFreeFrom - parseFloat(totalAmount.amount)
  return { amount: remainingAmount, currencyCode: totalAmount.currencyCode }
}

export const getVoucherDiscount = (cart: Cart | undefined): MoneyV2 => {
  if (!cart) return { amount: 0, currencyCode: CurrencyCode.Eur }

  const {
    cost: { subtotalAmount },
    lines: { nodes: items },
    discountAllocations,
  } = cart

  const lineDiscounts = items.map((item) => item.discountAllocations).flat()
  const discounts = discountAllocations
    .filter((discount) => discount.targetType !== DiscountApplicationTargetType.ShippingLine)
    .concat(lineDiscounts)
  const voucherDiscountAmount = discounts.reduce((acc, { discountedAmount: { amount } }) => acc - parseFloat(amount), 0)

  return { amount: voucherDiscountAmount, currencyCode: subtotalAmount.currencyCode }
}
