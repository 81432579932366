import clsx from 'clsx'

import { MENU_MOBILE_ITEM, MENU_MOBILE_SUBMENU } from 'src/selectors'

import styles from './MenuItem.module.scss'

import type { MouseEvent } from 'react'
import type { MenuItem as MenuItemType } from 'src/data/graphql-generated'

interface MenuItemProps {
  item: MenuItemType
  depth?: number
  openSubMenus: { [hash: string]: boolean }
  toggleSubMenu: (expandable: MenuItemType, e: MouseEvent) => void
  isMobileOnly?: boolean
}

export const MenuItem = ({ item, depth = 0, openSubMenus, toggleSubMenu, isMobileOnly = false }: MenuItemProps) => {
  const hasSubItems = item.items && !!item.items.length
  const isExpanded = openSubMenus[`${item.id}`]
  const isSubItem = depth > 0

  const children = (item.items || []).map((subItem, index) => {
    const mobileOnly = depth === 0 && index === 0

    return (
      <MenuItem
        key={subItem.id}
        depth={depth + 1}
        item={subItem}
        openSubMenus={openSubMenus}
        toggleSubMenu={toggleSubMenu}
        isMobileOnly={mobileOnly}
      />
    )
  })

  return (
    <li
      key={item.id}
      className={clsx(
        depth < 2 && styles.item,
        hasSubItems && styles.isExpandable,
        isExpanded && styles.isExpanded,
        isMobileOnly && styles.isMobile,
      )}
      data-cy={!isSubItem ? MENU_MOBILE_ITEM : undefined}
    >
      <a
        className={clsx(
          styles.link,
          isSubItem && styles.isSubmenu,
          hasSubItems && styles.isExpandable,
          isExpanded && styles.isExpanded,
          depth === 0 && styles.isWhite,
          depth === 1 && styles.isFull,
          depth === 2 && styles.isDetail,
        )}
        href={item.url}
        onClick={(e) => hasSubItems && toggleSubMenu(item, e)}
        data-testid="main-item"
        aria-expanded={isExpanded ? 'true' : 'false'}
        aria-haspopup={hasSubItems ? 'true' : 'false'}
      >
        {item.title}
      </a>

      {hasSubItems && depth === 0 && (
        <div className={clsx(styles.submenu, isExpanded && styles.isExpanded)} data-cy={MENU_MOBILE_SUBMENU}>
          <ul className={styles.submenusWrapper}>{children}</ul>
        </div>
      )}

      {hasSubItems && depth === 1 && (
        <ul className={clsx(styles.details, isExpanded && styles.isExpanded)}>{children}</ul>
      )}
    </li>
  )
}
