const openGraphImage =
  'https://cdn.coffeecircle.com/584ddc1f-2583-4640-81f6-e738ca0d7f64/-/resize/1000x/-/quality/lighter/cc-og-image.jpg'

export const config = {
  host: process.env.NEXT_PUBLIC_HOSTNAME,
  cmsHost: process.env.NEXT_PUBLIC_CMS_API_HOST,
  serviceHost: process.env.NEXT_PUBLIC_SERVICE_API_HOST,
  locales: ['de-DE', 'en-GB'],
  defaultLocales: {
    canonical: 'de-DE',
    xDefault: 'de-DE',
  },
  mainCoffeeCollectionId: 'gid://shopify/Collection/619532779849',
  mainGearCollectionId: 'gid://shopify/Collection/595161350473',
  donationProductId: 'gid://shopify/Product/8660935803209',
  donationAttributeKey: '_D',
  defaultSellingPlanInterval: 4,
  subscriptionDefaultVoucherCode: 'O39UWO7291QE',
  defaultSubscriptionDiscount: 10,
  socialProjectDefault: 'water',
  socialProjectDefaultIdentifier: 'SP022',
  socialProjects: {
    farming: 'SP023',
    education: 'SP024',
    water: 'SP022',
  },
  socialProjectsKey: {
    SP023: 'farming',
    SP024: 'education',
    SP022: 'water',
  },
  shippingFreeFrom: 39,
  deliveryTime: '1-3',
  shippingFee: 4.9,
  shippingCountries: [
    { code: 'AT', name: { en: 'Austria', de: 'Österreich' }, availableForSubscription: true },
    { code: 'BE', name: { en: 'Belgium', de: 'Belgien' }, availableForSubscription: true },
    { code: 'BG', name: { en: 'Bulgaria', de: 'Bulgarien' }, availableForSubscription: true },
    { code: 'CY', name: { en: 'Cyprus', de: 'Zypern' }, availableForSubscription: true },
    { code: 'CZ', name: { en: 'Czech Republic', de: 'Tschechien' }, availableForSubscription: true },
    { code: 'DK', name: { en: 'Denmark', de: 'Dänemark' }, availableForSubscription: true },
    { code: 'EE', name: { en: 'Estonia', de: 'Estland' }, availableForSubscription: true },
    { code: 'FI', name: { en: 'Finland', de: 'Finnland' }, availableForSubscription: true },
    { code: 'FR', name: { en: 'France', de: 'Frankreich' }, availableForSubscription: true },
    { code: 'DE', name: { en: 'Germany', de: 'Deutschland' }, availableForSubscription: true },
    { code: 'GR', name: { en: 'Greece', de: 'Griechenland' }, availableForSubscription: true },
    { code: 'HU', name: { en: 'Hungary', de: 'Ungarn' }, availableForSubscription: true },
    { code: 'IE', name: { en: 'Ireland', de: 'Irland' }, availableForSubscription: true },
    { code: 'IT', name: { en: 'Italy', de: 'Italien' }, availableForSubscription: true },
    { code: 'LV', name: { en: 'Latvia', de: 'Lettland' }, availableForSubscription: true },
    { code: 'LT', name: { en: 'Lithuania', de: 'Litauen' }, availableForSubscription: true },
    { code: 'LU', name: { en: 'Luxembourg', de: 'Luxemburg' }, availableForSubscription: true },
    { code: 'MT', name: { en: 'Malta', de: 'Malta' }, availableForSubscription: true },
    { code: 'NL', name: { en: 'Netherlands', de: 'Niederlande' }, availableForSubscription: true },
    { code: 'PL', name: { en: 'Poland', de: 'Polen' }, availableForSubscription: true },
    { code: 'PT', name: { en: 'Portugal', de: 'Portugal' }, availableForSubscription: true },
    { code: 'RO', name: { en: 'Romania', de: 'Rumänien' }, availableForSubscription: true },
    { code: 'SK', name: { en: 'Slovakia', de: 'Slowakei' }, availableForSubscription: true },
    { code: 'SI', name: { en: 'Slovenia', de: 'Slowenien' }, availableForSubscription: true },
    { code: 'ES', name: { en: 'Spain', de: 'Spanien' }, availableForSubscription: true },
    { code: 'SE', name: { en: 'Sweden', de: 'Schweden' }, availableForSubscription: true },
    { code: 'UK', name: { en: 'United Kingdom', de: 'Vereinigtes Königreich' }, availableForSubscription: false },
    { code: 'LI', name: { en: 'Liechtenstein', de: 'Liechtenstein' }, availableForSubscription: false },
    { code: 'NO', name: { en: 'Norway', de: 'Norwegen' }, availableForSubscription: false },
    { code: 'CH', name: { en: 'Switzerland', de: 'Schweiz' }, availableForSubscription: false },
  ],
  shippingIgnoredProductIds: ['gid://shopify/Product/8706651881801'],
  returnRefundTime: 14,
  openGraphImage,
  nextSeo: {
    title: 'Coffee Circle',
    titleTemplate: '%s | Coffee Circle',
    openGraph: {
      type: 'article',
      site_name: 'Coffee Circle',
      title: 'Kaffee online kaufen | Frisch geröstet bei Coffee Circle',
      description: `Erfahre alles über neue Trends in der Kaffeewelt, Rezeptideen, Tipps und Tricks oder Café Tipps.
► Nichts verpassen!`,
      images: [
        {
          url: openGraphImage,
          width: 1000,
        },
      ],
      article: { authors: ['Coffee Circle'] },
    },
    twitter: {
      handle: '@coffee_circle',
      site: '@coffee_circle',
      cardType: 'summary_large_image',
    },
    noindex: false,
    additionalMetaTags: [
      {
        name: 'keywords',
        content: 'Kaffee,Espresso,Shop',
      },
      {
        name: 'author',
        content: 'Coffee Circle',
      },
    ],
  },
  schemaOrganization: {
    '@context': 'http://schema.org',
    // * google search results hack
    // * this one "needs" to be product to show the star rating previews
    '@type': 'Product',
    'name': 'Coffee Circle',
    'sku': 'K000-0000-0001',
    'brand': {
      '@type': 'Brand',
      'name': 'Coffee Circle',
      'logo':
        'https://cdn.coffeecircle.com/03dd3f19-8367-43e0-a0ff-282c23f565cf/-/quality/lighter/coffeecirclelogowebsitebackground.jpg',
    },
    'description': `Nachhaltigen Kaffee und Zubehör im Online Shop bestellen ✓ Ganze Bohnen und gemahlen ✓ Fair und
direkt gehandelt`,
    'image': openGraphImage,
    'material': 'coffee',
    'email': 'kontakt@coffeecircle.com',
    'url': 'https://www.coffeecircle.com',
    'sameAs': [
      'https://www.youtube.com/c/coffeecircle',
      'https://www.facebook.com/coffeecircle',
      'https://www.instagram.com/coffeecircle',
      'https://twitter.com/coffee_circle',
    ],
    'aggregateRating': {
      '@type': 'AggregateRating',
      'ratingValue': 4.93,
      'reviewCount': 13702,
    },
  },
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  imagePlaceholderUploadcareId: 'c7e06e1c-4e56-4e54-9d31-0817a39ae029',
  cartRecommendationCollections: {
    gear: 'gid://shopify/Collection/630118613321',
    espressoCoffee: 'gid://shopify/Collection/630118187337',
    filterCoffee: 'gid://shopify/Collection/630117794121',
    mixed: 'gid://shopify/Collection/630118482249',
  },
}
