import clsx from 'clsx'

import styles from './DesktopGreenBar.module.scss'

import type { ReactElement } from 'react'

interface GreenBarButtonProps {
  item: GreenBarItem
  isCta?: boolean
}

export interface GreenBarItem {
  title: string
  url: string
  icon: ReactElement
}

export const GreenBarButton = ({ item, isCta }: GreenBarButtonProps) => (
  <li className={styles.item}>
    <a href={item.url} className={clsx(styles.link, isCta && styles.cta)}>
      {item.icon}
      <span className={styles.text}>{item.title}</span>
    </a>
  </li>
)
